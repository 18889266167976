(function() {
    angular.module('EntrakV5').controller('tenantAdminController', tenantAdminController);

    function tenantAdminController($scope, $rootScope, Service, Api, KEY, APIKEY, $state, $stateParams, $timeout) {
        console.log('tenantAdminController');

        //$stateParams.tenantId
        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $rootScope.hideTabs = true;

        $scope.admins = null;
        // $scope.INVITE_STATUS = APIKEY.invite;

        //init breadcrumbs
        $scope.steps = [ 
            $rootScope.STEP1, {
                name: '-'
            }
        ];

    /* first load */
        //update breadcrumbs display
        $rootScope.getLandlordId().then(function(id){
            $rootScope.loadingPage = 2;
            caller.call(Api.getTenant($stateParams.tenantId)).then(function(res){
                $scope.steps[1].name = res.name + " - " + Service.translate("tenant.viewTenantAdmin");
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                console.error('invalid floor/tenant id');
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
            caller.call(Api.getTenantAdmin($stateParams.tenantId)).then(function(res){
                $scope.admins = res;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        });
    /* first load */

    /* step2 (tenantAdmin) */
        $scope.editData = {};
        $scope.addTenantAdminWinOpt = {
            title: Service.translate("tenant.addTenantAdmin"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.addAdmin = function(){
            $scope.editData = {};
            setTimeout(function(){
                $scope.addTenantAdminWin.open().center();
            });
        }
        $scope.confirmAddAdmin = function(){
            $scope.btnStatus.saving = true;
            var email = $scope.editData.email.trim().toLowerCase();
            caller.call(Api.createTenantAdmin($stateParams.tenantId, email)).then(function(res){
                $scope.addTenantAdminWin.close();
                $scope.admins.push(res);
                $scope.btnStatus.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                if (err.graphQLErrors && (err = err.graphQLErrors[0])){
                    if (err.message === 'invalid email'){
                        alert(Service.translate("error.invalidEmail"));
                    } else if (err.message === 'create profile error'){
                        alert(Service.translate("error.duplicatedEmail"));
                    } else {
                        alert(Service.translate("error.generalCreateAccount"));
                    }
                } else {
                    alert(Service.translate("error.generalCreateAccount"));
                }
            });
        }

        $scope.inviteAdmin = function(admin){
            admin.inviting = true;
            caller.call(Api.inviteUser(admin.id)).then(function(res){
                admin.inviteStatusV2 = res.inviteStatusV2;
                admin.inviting = false;
                admin.inviteSuccess = true;
                $timeout(function(){
                    delete admin.inviteSuccess;
                }, 3000);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                admin.inviting = false;
                admin.inviteSuccess = false;
                alert(Service.translate("error.generalInviteFail"));
            });
        }

        $scope.deleteAdmin = function(admin){
            $rootScope.deletePopup.show("tenant.popup.deleteTenantAdmin", "tenant.popup.deleteTenantAdminDesc", admin.email, function(){
                caller.call(Api.deleteProfile(admin.id)).then(function(res){
                    Service.deleteArrItem($scope.admins, admin.id);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }
    /* step2 (tenantAdmin) */

        $scope.$on('$destroy', function() {
            console.log("tenantAdminController destroy");
            caller.cancel();
        });
    }
})();
